import { TLanguage} from "../../types/languageTypes";

//

const language: TLanguage = {
    ru:{
        craftTableStrings: {
            metrics: 'ед.',
            materialQuant: 'Использ. материал',
            subMatsQuant: 'Использ. доп. мат.',
            returnPercentage: 'Процент возврата',
            itemsOutput: 'Выход вещей',
            deleteButton: 'Удалить',
            materialTier: 'Тир',
            resQuant: 'Использ. ресурс',
            materialsOutput: 'Выход',
            alert: 'Расчетов нет.',
            unPerItem: 'ед. на предмет',
        },
        calculatorFormStrings: {
            craftHeader: {
                ITEMS: 'Перекрафт материалов в экипировку:',
                RESOURCES: 'Перекрафт ресурсов в материалы:',
                POTIONS: 'Крафт зелий:',
                FOOD: 'Крафт еды:',
            },
            metrics: 'ед.',
            labelResourceAmount: 'Количество ресурса: ',
            labelItemsAmount: 'Количество материалов: ',
            labelOwnPercent: 'Свой процент: ',
            labelPercent: 'Процент возврата: ',
            percentError: 'Процент не может быть ниже 15,2% и выше 70%!',
            amountError: 'Количество материалов должно быть достаточным!',
            type: 'Тип: ',
            materialsOutput: 'Приблизительный выход материалов',
            itemsOutput: 'Приблизительный выход предметов',
            itemTier: 'Тир: ',
            craftListHeader: 'Предыдущие 10 расчетов',
            subMaterialsQuantity: 'Доп. материалы(если требуются)',
            copied: 'скопировано!',
            total: 'итого:',
        },
        infoTableStrings: {
            profitPerItem: 'Профит за предмет:',
            total: 'Итого:',
            noData: 'нет данных',
            from: ' из: ',
            item: 'Предмет',
            saleIn: 'Продажа в',
            un: 'ед.',
            tax: 'Плата за 100 еды:',
            taxPerOneItem: 'Налог за 1 предмет:',
            taxLabel: 'Налог:',
            journalLabel: 'Использовать журналы:',
            buyJournals: 'Купить пустые журналы в:',
            sellJournals: 'Продать полные журналы в:',
            journalQuantity: 'Журналов при крафте 1 предмета:',
            journalProfit: 'Профит от журналов при крафте 1 предмета:',
            errorNotification: 'Ошибка загрузки предметов!',
            multipleCities: 'Цены из разных городов:',
            resourcesFrom: 'Ресурсы из:',
        },
        artefactsStrings: {
            sortLabel: 'Сортировать:',
            itemValueDescendingSelector: 'По убыванию ценности',
            itemValueAscendingSelector: 'По возрастанию ценности',
            itemValueTitle: 'ценность предмета: ',
            copyState: 'скопировано!',
            resetButtonTitle: 'Сброс маркера',
            value: 'Ценность:',
            city: 'Город:',
            price: 'Цена:',
            getArtefactsPrice: 'Обновить цену артефактов:',
            resetAllMarkers: 'Сброс всех маркеров:',
            artefactsTier: 'Тир артефактов:',
        },
        transportationFormStrings: {
            server: 'Сервер:',
            from: 'из:',
            albionWest: 'Америка (Вашингтон)',
            albionEast: 'Азия (Сингапур)',
            into: 'в:',
            sortBy: 'Сортировать по:',
            percentageProfit: 'Прибыли в процентах',
            silverProfit: 'Прибыли в серебре',
            profitVolume: 'Прибыли в объеме',
            sortByLastChecked: 'Сортировка по последней проверкe:',
        },
        transportationTableStrings: {
            requestError: 'Ошибка запроса, обновите страницу!',
            item: 'Предмет',
            itemName: 'Наименование',
            excludingTax: 'с вычетом налога',
            profit: 'Прибыль',
            percentageProfit: 'Прибыль в %',
            dailyTurnover: 'Суточный оборот',
            copyAlert: 'Скопировано!',
            unPerDay: 'ед/сут',
        },
        backpackString: {
            gatherButton: 'Собрать',
            sortButton: 'Сортировать',
            iconTitle: 'Условное серебро. После выполненного крафта, который уведет балан в минус, и продажи которая принесет условный профит или убыток, можно будет наглядно посмотреть профитность крафтового цикла определенной вещи в игре.'
        },
        marketItemStings: {
            ownPriceLabel: 'Получить цену с сервера:',
            actionLabel: 'Действие:',
            sell: 'Продать',
            buy: 'Купить',
            amountLabel: 'Количество:',
            priceLabel: 'Цена:',
            totalPriceLabel: 'Всего:',
            tax: 'Налог на продажу 6.5%',
            tier: 'Уровень',
            enchantment: 'Зачарование',
        },
        marketMenuStrings: {
            marketHeader: 'Рынок',
            sell: 'Продать',
            buy: 'Купить',
            alert: 'Ваш Рюкзак пуст.',
            search: 'Поиск'
        },
        GMItemSelectorStings: {
            dialogText: 'Здравствуйте! Поглядите на мой товар.',
            nutritionUsage1: 'Плата использования здания за потребленные',
            nutritionUsage2: '100 единиц еды:',
            search: 'Поиск',
        },
        GMCraftingFormStrings: {
            journalUsageButton: 'Использовать журналы:',
            journalInfoIconText: `Получить цену ОДНОГО журнала с сервера или ввести собственную цену.`,
            price: 'Цена:',
            quantity: 'Количество:',
            enchantment: 'Чары:',
            resourceLabel: 'Ресурсы:',
            returnRateLabel: 'Возврат ресурсов:',
            artefactLabel: 'Цена артефакта:',
            artefactInfoIconText: 'Получить цену ОДНОГО артефакта с сервера или ввести собственную цену. Цена всех артефактов:',
            totalPriceLabel: 'Цена в серебре:',
            foodTaxInfoIconText: 'Цены за станок с учетом зачарования.'
        },
        headerStrings: {
            server: 'Сервер',
            language: 'Язык',
        },
        notFoundStings: {
            notFound: 'Страница не найдена!',
            returnTitle: 'Вернуться на домашнюю страницу.',
        },
        homeStrings: {
            artefacts: 'Артефакты',
            craftCalculator: 'Крафт',
            transportation: 'Транспортировка',
            artefactsDescription: 'В разделе "Артефакты" Вы можете посмотреть все цены артефактов, полученные из Albion Online Data Project, а так же получить все наименования артефактов для более легкого поиска на рынке.',
            craftCalculatorDescription: 'В разделе "Крафт" есть два калькулятора - с помощью обычного калькулятора можно быстро посчитать профит от крафта определенного тира, а так же сопутствующих зачарований, с помощью же "Game Mode" режима можно наглядно посчитать профит так, как это происходило бы в игре, только без беготни.',
            transportationDescription: 'В разделе "Транспортировка" с помощью базы данных Albion Online Data Project отсортированы самые выгодные предметы для продажи из любого города на Черный рынок или же в любой другой город.',
            language: 'язык',
        },
        FAQStrings: {
            description1: 'Данный сайт не является коммерческим, разрабатывался в качестве обучающего пет проекта Front-end девелопера! Разработчик -',
            description2: 'Вся загружаемые цены берутся с баз данных сайта',
            description3: 'По вопросам можно обратиться в',
        },
    },
    en:{
        craftTableStrings: {
            metrics: ' un.',
            materialQuant: 'Mats. Quant.',
            subMatsQuant: 'Sub Mats. Quant.',
            returnPercentage: 'return %',
            itemsOutput: 'Items output',
            deleteButton: 'Delete',
            materialTier: 'Material tier',
            resQuant: 'Res. Quant.',
            materialsOutput: 'Mats output',
            alert: 'No calculations.',
            unPerItem: 'un. per item',
        },
        calculatorFormStrings: {
            craftHeader: {
              ITEMS: 'Сrafting materials into equipment:',
              RESOURCES: 'Сrafting resources into materials:',
              POTIONS: 'Potions craft:',
              FOOD: 'Food craft:',
            },
            metrics: 'un.',
            labelResourceAmount: 'Resource quantity: ',
            labelItemsAmount: 'Materials quantity: ',
            labelOwnPercent: 'Your percentage: ',
            labelPercent: 'Return percentage: ',
            percentError: 'The percentage must be between 15,2% and 70%!',
            amountError: 'The initial quantity must be sufficient!',
            type: 'Type: ',
            materialsOutput: 'Approximate Product Output: ',
            itemsOutput: 'Approximate Items Output: ',
            itemTier: 'Tier: ',
            craftListHeader: 'Previous 10 calculations',
            subMaterialsQuantity: 'Required amount of sub materials: ',
            copied: 'copied!',
            total: 'total:',
        },
        infoTableStrings: {
            profitPerItem: 'Profit per item:',
            total: 'Total:',
            noData: 'no data',
            from: ' from:',
            item: 'Item',
            saleIn: 'Sell in',
            un: 'un.',
            tax: 'Fee per 100 nutrition:',
            taxPerOneItem: 'Tax per 1 item:',
            taxLabel: 'Tax:',
            journalLabel: 'Use journals:',
            buyJournals: 'Buy empty journals in:',
            sellJournals: 'Sell full journals in:',
            journalQuantity: 'Journal quantity per 1 item crafted:',
            journalProfit: 'Journal profit per 1 item crafted:',
            errorNotification: 'Error loading items!',
            multipleCities: 'Use multiple cities:',
            resourcesFrom: 'Resources from:',
        },
        artefactsStrings: {
            sortLabel: 'Sort:',
            itemValueDescendingSelector: 'Descending item value',
            itemValueAscendingSelector: 'Ascending item value',
            itemValueTitle: 'item value: ',
            copyState: 'copied!',
            resetButtonTitle: 'Marker reset',
            value: 'Value:',
            city: 'City:',
            price: 'Price:',
            getArtefactsPrice: 'Update artifacts prices:',
            resetAllMarkers: 'Reset all markers:',
            artefactsTier: 'Artifacts tier:',
        },
        transportationFormStrings: {
            server: 'Server:',
            from: 'from:',
            albionWest: 'Albion West(Washington DC)',
            albionEast: 'Albion East(Singapore)',
            into: 'into:',
            sortBy: 'Sort by:',
            percentageProfit: 'Percentage profit',
            silverProfit: 'Profit',
            profitVolume: 'Profit volume',
            sortByLastChecked: 'Sort by last checked:',
        },
        transportationTableStrings: {
            requestError: 'Request error, please refresh the page!',
            item: 'Item',
            itemName: 'Item Name',
            excludingTax: 'excluding tax',
            profit: 'Profit',
            percentageProfit: 'Percentage profit',
            dailyTurnover: 'Daily turnover',
            copyAlert: 'Copied!',
            unPerDay: 'un/d',
        },
        backpackString: {
            gatherButton: 'Stack',
            sortButton: 'Sort',
            iconTitle: 'Conditional silver. After completing the craft, which will take the balance into the red, and the sale, which will bring a conditional profit or loss, it will be possible to visually see the profitability of the crafting cycle of a certain item in the game.'
        },
        marketItemStings: {
            ownPriceLabel: 'Get price from server:',
            actionLabel: 'Action:',
            sell: 'Sell',
            buy: 'Buy',
            amountLabel: 'Amount:',
            priceLabel: 'Price:',
            totalPriceLabel: 'Total:',
            tax: 'Sales tax 6.5%',
            tier: 'Tier',
            enchantment: 'Enchantment'
        },
        marketMenuStrings: {
            marketHeader: 'Marketplace',
            sell: 'Sell',
            buy: 'Buy',
            alert: 'Your inventory is empty.',
            search: 'Search'
        },
        GMItemSelectorStings: {
            dialogText: 'Hello traveler, come closer and have a look.',
            nutritionUsage1: 'Usage fee per 100 Nutrition',
            nutritionUsage2: 'consumed:',
            search: 'Search'
        },
        GMCraftingFormStrings: {
            journalUsageButton: 'Use journals:',
            journalInfoIconText: `Get the price of ONE journal from the server or enter your own price.`,
            quantity: 'Quantity:',
            price: 'Price:',
            enchantment: 'Enchantment:',
            resourceLabel: 'Resources:',
            returnRateLabel: 'Resource Return Rate:',
            artefactLabel: 'Artifact\'s price:',
            artefactInfoIconText: 'Get the price of ONE artifact from the server or enter your own price. Price of all artifacts:',
            totalPriceLabel: 'Silver Cost:',
            foodTaxInfoIconText: 'Food tax including enchantment.',
        },
        headerStrings: {
            server: 'Server',
            language: 'Language',
        },
        notFoundStings: {
            notFound: 'This page could not be found!',
            returnTitle: 'Return to the Home page.',
        },
        homeStrings: {
            artefacts: 'Artifacts',
            craftCalculator: 'Craft',
            transportation: 'Transportation',
            artefactsDescription: 'In the "Artifacts" section you can see all the prices of artifacts obtained from the Albion Online Data Project, as well as get all the names of artifacts for an easier search on the market.',
            craftCalculatorDescription: 'In the "Crafting" section, you\'ll find two calculators. The standard calculator allows you to quickly estimate the profit from crafting a specific tier and its associated enchantments. Meanwhile, the "Game Mode" calculator provides a visual simulation of the crafting process, showing potential profits as they would appear in-game, but without the need for running around.',
            transportationDescription: 'In the "Transportation" section, the most profitable items for sale from any city to the Black Market or to any other city are sorted using the Albion Online Data Project database.',
            language: 'language',
        },
        FAQStrings: {
            description1: 'This site is not commercial, it was developed as a training project for a Front-end developer. Developer is -',
            description2: 'All downloaded prices are taken from',
            description3: 'If You have questions you can contact',
        },
    }
};
export default language;
